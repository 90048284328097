<template>
  <div id="App">
    <div id="app-list">
      <div id="app-left">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div id="app-right">
        <div id="search">
          <div>
            <el-input
              size="small"
              class="el-input"
              v-model="input"
              placeholder="关键字"
            /><el-button
              class="el-button"
              size="small"
              style="width: 60px"
              type="primary"
              >搜索</el-button
            >
          </div>
        </div>
        <div id="navigation">
          <ul
            :ref="domList2"
            @click="onclick(i, index.router)"
            @mouseover="onmouseover(i)"
            @mouseout="onmouseout(i)"
            v-for="(index, i) in titileList"
            :key="i"
          >
            <li>{{ index.name }}</li>
            <div :ref="domList1"></div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import { mapState, mapMutations } from "vuex";
const myRef = ref([]);
const domList1 = ref();
const domList2 = (el) => {
  myRef.value.push(el);
};

export default {
  components: {},
  //数据
  setup() {
    return {
      domList1,
      domList2,
      titileList: [
        {
          name: "首页",
          router: "/",
        },
        {
          name: "关于我们",
          router: "/Journalism",
        },
        {
          name: "新闻动态",
          router: "/news",
        },
      ],
      showlist: 0,
      num: 0,
    };
  },

  watch: {
    text(n, o) {
      console.log(n);
      let array = [1, 2, 3];
      array.forEach((element, e) => {
        if (e === this.text) {
          myRef.value[this.text].childNodes[1].style.width = "100%";
          myRef.value[this.text].childNodes[1].style.left = "0%";
          myRef.value[this.text].childNodes[0].style.color = "#09c";
        } else {
          myRef.value[e].childNodes[1].style.width = "0";
          myRef.value[e].childNodes[1].style.left = "50%";
          myRef.value[e].childNodes[0].style.color = "#333";
        }
      });
    },
  },
  computed: {
    ...mapState(["text", "Product_listB"]),
  },
  created() {
    console.log(parseInt(localStorage.getItem("stepte")));
  },
  mounted() {
    myRef.value[0].childNodes[1].style.width = "100%";
    myRef.value[0].childNodes[1].style.left = "0%";
    myRef.value[0].childNodes[0].style.color = "#09c";
    this.ontext();
  },
  methods: {
    ...mapMutations(["add"]),
    ontext() {
      let array = [1, 2, 3];
      array.forEach((element, e) => {
        if (e === this.text) {
          myRef.value[this.text].childNodes[1].style.width = "100%";
          myRef.value[this.text].childNodes[1].style.left = "0%";
          myRef.value[this.text].childNodes[0].style.color = "#09c";
        } else {
          myRef.value[e].childNodes[1].style.width = "0";
          myRef.value[e].childNodes[1].style.left = "50%";
          myRef.value[e].childNodes[0].style.color = "#333";
        }
      });
    },
    onclick(i, r) {
      this.add(i);
      let id = encodeURIComponent(
        JSON.stringify(this.Product_listB[0].newtext)
      );
      this.$router.push({
        //核心语句
        path: r, //跳转的路径
        query: {
          id,
        },
      });
    },
    onmouseover(i) {
      let array = [1, 2, 3];
      array.forEach((element, e) => {
        if (e === i) {
          myRef.value[i].childNodes[0].style.color = "#09c";
        }
      });
    },
    onmouseout(i) {
      let array = [1, 2, 3];
      array.forEach((element, e) => {
        if (e === i && this.text !== i) {
          myRef.value[i].childNodes[0].style.color = "#333";
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
#App {
  height: 140px;
  background: white;
  margin-top: 10px;

  #app-list {
    width: 62%;
    height: 100%;
    display: flex;
    margin: auto;
    @media screen and (max-width: 1300px) {
      margin-left: 10%;
    }
    @media screen and (max-width: 1070px) {
      margin: 0;
    }
    @media screen and (max-width: 800px) {
      #app-left {
        width: 0;
      }
    }
    #app-left {
      width: 42%;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        width: 200px;
      }
    }
    #app-right {
      width: 750px;
      height: 100%;

      #search {
        height: 40px;
        overflow: hidden;
        div {
          float: right;
          display: flex;
          .el-input {
            width: 240px;
          }
        }
      }
      #navigation {
        width: 750px;
        height: 100px;
        display: flex;
        align-items: center;
        ul {
          flex: 1;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 13px;
          border-radius: 10px;
          margin-left: 4px;
          position: relative;
          div {
            width: 0;
            height: 4px;
            position: absolute;
            background: #09c;
            bottom: 0;
            left: 50%;
            transition: all 0.5s;
          }
        }
        ul:hover {
          color: #09c;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
