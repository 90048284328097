<template>
  <div class="home" :style="cssVars">
    <titlea />
    <navigation />
    <router-view></router-view>
    <foot />
     <el-backtop />
  </div>
</template>

<script>
import titlea from "./components/title.vue";
import navigation from "./components/navigation.vue";
import foot from "./components/foot.vue";
export default {
  name: "index",
  data() {
    return {
      winWidth: "",
      winHeight: "",
    };
  },
  components: {
    titlea,
    navigation,
    foot,
  },
  computed: {
    cssVars() {
      return {
        "--cowinWidthor": this.winWidth,
        "--cowinHeight": this.winHeight,
      };
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.home {
  @media screen and (max-width: 960px) {
    min-width: 960px;
  }
}
</style>
