<template>
  <div id="App">
    <div @click="ontext" id="text">
      <div id="textA">
        <div>
          <img src="@/assets/jincha.jpg" alt="" />
        </div>
        <a href="https://beian.miit.gov.cn" target="_blank"
          >ICP证备案：沪ICP备2021004728号-1</a
        >
      </div>
      <div id="textB">
        <div>易手客小程序</div>
        <img class="image" src="@/assets/erweima.png" alt="" />
      </div>
    </div>
     
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    ontext() {},
  },
};
</script>

<style scoped lang='scss'>
#App {
  background: rgb(50, 139, 168);
  overflow: hidden;
  #text {
    margin: 20px auto;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    #textA {
      display: flex;
      div {
        width: 28px;
        height: 28px;
        overflow: hidden;
      }
      a {
        margin-left: 2px;
        color: white;
      }
    }
    #textA:hover {
      a {
        color: rgb(228, 207, 157);
        text-decoration: underline;
      }
    }
    #textB {
      color: white;
      margin: 0 20px;
      img {
        margin-top: 10px;
        width: 100px;
      }
    }
  }
}
</style>
