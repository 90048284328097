<template>
  <div class="wid-100 hig30 li-he30">
    <div class="title">
      <span>上海弛迅科技有限公司欢迎您！</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.li-he30 {
  background: #09c;
  color: aliceblue;
  font-size: 14px;
  .title {
    width: 62%;
    margin: auto;
  }
}
</style>
